import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import CardWidget from "../../widgets/cardWidget"
import slugify from "slugify"

const useStyle = makeStyles(theme => ({
  wrapper: {
    paddingRight: "0.62vw",
    "@media(min-width: 1280px)": {
      paddingRight: "8px",
    },
    "@media(max-width: 1025px)": {
      paddingRight: "0.95vw",
    },
    "@media(max-width: 767px)": {
      paddingRight: "1.93vw",
    },
  },
}))

/**
 * Набор тэгов продуктов для блока быстрых ссылок на странице подкатегории
 * @module src/components/catalog/filtersByTag
 * @param {Object} props - объект свойств компонента React
 * @param {Object[]} props.products - массив объектов продуктов полученый из prismic
 */
export default function FiltersByTag({ products, catalogMainLink }) {
  const classes = useStyle()
  // собираем тэги  отображаемых продуктов
  const tags = []
  let model = "",
    brand = ""

  products.forEach(product => {
    model = product?.data?.code_model
    brand = product.data.brand?.uid
    product.data.tags.forEach(tag => {
      if (tag?.tag?.document === null || tag.tag === null) return

      tags.push({
        tagTitle: tag?.tag.document?.data.name,
        modelTitle: model,
        brandTitle: brand,
        modelDocument: product?.data?.model_document?.document?.uid,
      })
    })
  })
  function isObjectUnique(obj, index, self) {
    return index === self.findIndex(o => o.modelTitle === obj.modelTitle)
  }
  const uniqueTags = tags.filter(isObjectUnique)
  return (
    <>
      {[...uniqueTags].map(tag => {
        const title = tag?.modelTitle.replace("ё", "е")
        return (
          <div key={tag} className={classes.wrapper}>
            <CardWidget
              variant="small"
              cardTitle={title}
              cardLink={
                catalogMainLink +
                tag.brandTitle.toLowerCase() +
                "/" +
                (tag.modelDocument !== undefined
                  ? tag.modelDocument
                  : slugify(tag.modelTitle.toLowerCase()))
              }
            />
          </div>
        )
      })}
    </>
  )
}
